.slider-toko{
    margin-top: 61px
}
.gallery-item {
    border-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    margin-bottom: 5px;
}

.gallery-item-container {
    margin: 10px;
    margin-bottom: 20px;
}

/* .count-gallery-item{
    color: grey;
} */

.see-more-pink{
    color: #ED0089!important;
    align-items: center!important;
}


.wrapper-merch-page{
    padding-top: 8em;
    padding-left: 5vw;
    padding-right: 5vw;
}

.container-section {
    height: auto;
    padding-left: 5vw;
    padding-right: 5vw ;
    padding-top: 5vw;
}

.filter-container-art-section-wrapper{
    margin-bottom: 2em;
}

.shop-list-container{
    margin-bottom: 70px;
}

.product-list{
    cursor: pointer;
    background-color: #757575;
    width: 20vw!important;
    height: 100%!important;
    background-repeat: no-repeat;
    background-size: cover;
}

.container-merch-mobile .product-list {
    cursor: pointer;
    background-color: #757575;
    width: 100%!important;
    height: 100%!important;
    background-repeat: no-repeat;
    background-size: cover;
}

.sort{
    border-radius: 50px;
    max-width: 300px;
}

.container-sort{
    min-width: 20vw;
    align-items: center;
}

.container-sort label{
    margin-bottom: 0px!important;
    margin-right: 10px;
    width: 50%;
}

.show-modal{
    display: block;
    position: fixed;
    
}

.modal-detail {
    height: 100vh;
    width: 100vw;
    background-color: rgba(2, 2, 2, 0.658);
    z-index:100000;
    top: 0vh;
    display: none!important;

}

.image-product{
    height: 600px;
    width: 400px;
    background-color: #757575;
}
.container-product{
    /* height: 100%;
    padding: 40px;
    display: flex;
    justify-content: center;
    width: 80%; */
    height: 100%;
    padding: 2rem 0rem;
    display: flex;
    justify-content: center;
    width: 80%;
}
.container-image-product{
    /* height: 600px; */
    margin-right: 15px;
}
.container-detail-product{
    margin-left: 15px;
    height: 100%
}
.button-toko{
    width:48%;
    height: 70px;
    border-radius: 50px;
    margin-right: 10px;
    margin-bottom: 20px;
    justify-content: center;
    display: flex;
    align-items: center;
    box-shadow: -2px 6px 12px -7px rgba(0,0,0,0.46);
    -webkit-box-shadow: -2px 6px 12px -7px rgba(0,0,0,0.46);
    -moz-box-shadow: -2px 6px 12px -7px rgba(0,0,0,0.46);
}
.container-wrapper{
    background-color: white;
    height: 100%;
    width: 100%;
}
.button-toko img{
    height: 50px;
}
.container-buttons{
    flex-wrap: wrap;
    
}
.product-list img{
    width: 100%;
}
.detail-paragraf{
    border-bottom: rgb(161, 161, 161);
    border-bottom-style: solid;
    border-top: rgb(161, 161, 161);
    border-top-style: solid;
    padding-top: 20px;
    padding-bottom: 10px;;
    border-width: 1px;
    margin-bottom: 30px;
}

.container-button-close{
    background-color: transparent;
    float: right;
    height: 10vh;
    width: 100vw;
    padding-right: 20px;
    
}

.button-close{
    width: 20px;
    float: right;
    color: white;
    position: absolute;
    right: 10px;
    top: 6%;
    cursor: pointer;
    font-size: 30px;
}

.hide-modal{
    animation: flyout .3s ease-in;
    /* display: none!important; */
}

.show-modal{
    display: block!important;
    animation: fly .3s ease-in;
}


.shop-list-container{
    margin: 1vw;
    height: 100%;
}

.container-merch-mobile .shop-list-container{
    width: 100%;
    height: 100%;
}
.modal-copied {
    background-color: black;
    color: white;
    padding: 10px;
    margin: auto;
    text-align: center;
    bottom:0;
    left: 50%;
    transform: translateX(-50%);
    position: fixed;
    z-index: 2000;
}

@keyframes fly {
    0%   { 
    transform: translateY(100%); 		
    }
    100% { 
    transform: translateY(0%); 
    }
   }


   @keyframes flyout {
    100%   { 
    transform: translateY(100%); 		
    }
    0% { 
    transform: translateY(0%); 
    display: none!important;
    }
   }