.slider-promoc2{
  margin-top: 61px;
}
.section-wrapper-promoc2{
    padding-left: 5vw;
    padding-right: 5vw;
    padding-top: 8em;
    .filter-container-promoc2-section-wrapper{
      margin-bottom: 2em;
      .filter-container-promoc2-section{
        width: 90vw;
        overflow-y: hidden;
      }
      .badge-promoc2-section{
        cursor: pointer;
        background: #F67DC3;
        color: #ffffff;
        font-size: 16px;
        border-radius: 30px;
        font-style: normal;
        font-weight: bold;
        width: 117px;
        height: 40px;
        text-align: center;
        padding-top: 7px;
        text-transform: none;
        margin-right: 10px
    }
    
    .badge-promoc2-section-active{
        cursor: pointer;
        background: #ED0089;
        color: #ffffff;
        font-size: 16px;
        border-radius: 30px;
        font-style: normal;
        font-weight: bold;
        width: 117px;
        height: 40px;
        text-align: center;
        padding-top: 7px;
        text-transform: none;
        transition: .5s;
          // transform: scale(1.1);
        margin-right: 10px
    }
    }
    .section-list-promoc2{
      margin: 1.5em 0px;
      display: flex;
      .item-list-promoc2-thum{
        display: block;
        width: 500px;
        height: 294px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
      }
      .item-list-promoc2-content{
        padding-left: 1em;
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .item-list-promoc2-thum-mobile{
          display: none;
          height: 35vh;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
        }

        .item-list-title{
          font-weight: bold;
          font-size: 20px;
          line-height: 24px;
          padding-right: 1em;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        .item-list-desc{
          margin: 1em 0px;
          padding-right: 5em;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
          font-style: normal;
          font-weight: normal;
          line-height: 20px;
          // color: #444444;
          font-size: 15px;
          p{
            display: inline;
          }
        }
        .item-list-promoc2-tag{
          margin: 8px 0px;
          display: flex;
          flex-wrap: wrap;
          .badge-promoc2-category-tag{
            cursor: pointer;
            background: #F67DC3;
            color: #ffffff;
            font-size: 15px;
            border-radius: 30px;
            font-style: normal;
            font-weight: bold;
            // width: 56px;
            height: 25px;
            text-align: center;
            padding-top: 4.1px;
            padding-left: 5px;
            padding-right: 5px;
            text-transform: none;
            margin: 5px 5px 5px 0px;
          }
        }
        .badge-promoc2-readmore{
          cursor: pointer;
          background: #ED0089;
          color: #ffffff;
          font-size: 16px;
          border-radius: 30px;
          font-style: normal;
          font-weight: bold;
          width: 117px;
          height: 40px;
          text-align: center;
          padding-top: 7px;
          text-transform: none;
        }
      }
    }
}

@media(max-width: 500px) {
  .section-list-promoc2{
    margin: 2em 0px !important;
  }

  .item-list-promoc2-thum{
    display: none !important;
  }
  .item-list-promoc2-thum-mobile{
    display: block !important;
    margin-bottom: 1em;
  }
  .item-list-promoc2-content{
    padding-left: 0px !important;
  }
  .item-list-title{
    padding-right: 0px !important;
    -webkit-line-clamp: 1 !important;
  }
  .item-list-desc{
    padding-right: 0px !important;
    -webkit-line-clamp: 4 !important;
  }
  .badge-promoc2-readmore{
    width: 80% !important;
    display: block;
    margin: auto;
  }
}

