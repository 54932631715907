
#slider-karya{
    width: 100vw;
  
    
}

.container-slider{
    /* height: 300px; */
    /* margin-top:62px; */
   
}
.slider-content{
    /* background-color: silver; */
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: white;
}
#next-arrow, #prev-arrow{
    position: absolute;
    top: 40%;
}

#prev-arrow {
    cursor: pointer;
    left: 60px;
    z-index: 100;

}
#prev-arrow > img{
    width: 3em;
    height: 3em;
}

#next-arrow{
    cursor: pointer;
    right: 60px;
}

#next-arrow > img{
    width: 3em;
    height: 3em;
}

.slider-content{
    height: 15vw;
    background-color: black!important;
}

