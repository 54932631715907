  .featured-event{
    cursor: pointer;
  }
  .fe-footer{
    height: 42px;
    width: 160px;
    background-color: #31363A;
    border-radius: 0px 0px 12px 12px;
    border-top: 1px solid #535B62;
    padding: 0.5em 0px 0px 1em;
    .fe-list-title{
      font-weight: 700;
      font-size: 16px;
      line-height: 19.2px;
      color: #F9F9F9;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1; /* number of lines to show */
              line-clamp: 1; 
      -webkit-box-orient: vertical;
    }
  }
  .fe-content{
    width: 160px;
    height: 140px;
    background-color: #31363A;
    padding: 1em;
    .fe-list-title{
      font-weight: 700;
      font-size: 16px;
      line-height: 19.2px;
      color: #F9F9F9;
      padding-bottom: 0.5em;
    }
    .fe-list-title-small{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 400;
      font-size: 14px;
      line-height: 16.8px;
      color: #F9F9F9;
      padding-bottom: 0.5em;
      margin-left: -10px;
      margin-top: 2px;
    }
  }

  .fe-list-event{
    margin: 1.5em 0px 0px 0px;
    // display: flex;
    .fe-list-event-thum{
      // display: block;
      width: 160px;
      height: 160px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      border-radius: 12px 12px 0px 0px;
    }
    
    .fe-list-event-content{
      padding-left: 1em;
      width: 100%;

      .fe-list-title{
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        padding-right: 1em;
        overflow: hidden;
        text-overflow: ellipsis;
        // display: -webkit-box;
        // -webkit-line-clamp: 3;
        // -webkit-box-orient: vertical;
      }
    }
  }

  @media(max-width: 500px) {
    .featured-event{
      padding-right: 3vw;
    }
  }