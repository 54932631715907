.wrapper-button-floating-section {
  position: fixed;
  bottom: 35px;
  right: 40px;
  z-index: 1045;
  .button-floating-add-icon{
    cursor: pointer;
    position: relative;
    width: 50px;
    height: 50px;
    background: #ed0089;
    border-radius: 40px;
    color: #ffffff;
    font-size: 20px;
      i{
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
  }
  .button-active-flaoting-text{
    opacity: 1;
    position: absolute;
    z-index: 990;
    right: 50px;
    top: 25px;
    transition: opacity 1s;
    -webkit-transition: opacity 1s;
    .button-floating-add-icon-text{
      position: relative;
      width: 72px;
      width: 230px;
      height: 50px;
      border-radius: 40px;
      background: #ed0089;
      color: #ffffff;
      cursor: pointer;
      .wrapper-button-floating-text{
        position: absolute;
        left: 48%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        display: flex;
      }
      .icon-btn-flaoting-text{
        font-size: 20px;
       }
       .btn-flaoting-text-nonicon{
        font-size: 15px;
        margin-left: 7px;
        margin-top: 2px;
       }
    }
  }

  .button-flaoting-text{
    opacity: 0;
    position: absolute;
    z-index: 990;
    right: 16px;
    top: 90px;
  }


}

