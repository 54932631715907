.item {
  transition: transform 300ms ease 100ms;
  margin: 0 2px;
  position: relative;
  background-size: cover;
  background-position: center center;
  height: 137px;
  aspect-ratio: 16 / 9;
  width: 246px;

  img {
    width: 100%;
    vertical-align: top;
  }
  .slider-content-box {
    font-size: 10px;
    padding: 1em;
    position: absolute;
    top: 0;
    bottom: 0;
    background-image: linear-gradient(
      to top,
      #000000,
      rgba(0, 0, 0, 0.9),
      rgba(0, 0, 0, 0.5),
      transparent
    );
    display: none;
    // z-index: 3;
    .thunmb-section-icon-top{
      display: flex;
      .prefix-thumb-playicon{
        margin-left: 1em;
      }
    }
  }
  &:hover {
    .slider-content-box {
      display: block;
    }
  }

  .thumbarrow-sec {
    .thumbarrow-white {
      display: none;
    }
    .thumbarrow-red {
      display: block;
    }
  }

  .show-details-button {
    span {
      display: none !important;
    }
  }
}

.slider--open {
  .item {
    &:hover {
      transform: scale(1.1, 1.1);
      z-index: 1000;
      transition: transform 800ms ease 100ms;
      box-shadow: -1px 5px 19px -1px rgba(0,0,0,0.45);
      -webkit-box-shadow: -1px 5px 19px -1px rgba(0,0,0,0.45);
      -moz-box-shadow: -1px 5px 19px -1px rgba(0,0,0,0.45);
      transform: translateX(-5%,5%);
      .slider-content-box {
        display: none;
      }
    }
    
  }

   .item:hover {
    transform: scale(1.1) !important;
  }

  :hover .item {
    transform: translateX(-5%);
  }

   .item:hover ~ .item {
    transform: translateX(5%);
  }
  
}
