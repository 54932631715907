.section-wrapper-artikel{
    padding-left: 5vw;
    padding-right: 10vw;
    padding-top: 8em;
    .border-artikel{
      border-top: 12px solid #ED0089;
    }
    .section-list-artikel{
      margin: 1.5em 0px;
      display: flex;
      .artikel-list-thum{
        display: block;
        width: 30vw;
        height: 40vh;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
      }
      .artikel-list-content{
        padding-left: 1em;
        width: 100%;
        display: none;
        flex-direction: column;
        overflow: hidden;
        .artikel-list-thum-mobile{
          display: none;
          height: 35vh;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
        }
      }
    }
}

.feature-artikel-mobile{
  margin: 1.5em 0px;
  .feature-artikel-mobile-content{
    padding-left: 1em;
    width: 100%;
    display: none;
    flex-direction: column;
    overflow: hidden;
    .feature-artikel-mobile-thum{
      display: none;
      height: 35vh;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }
  }
  .feature-artikel-mobile-title{
    display: none;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 1vh;
    margin-bottom: -1vh;
  }
  .feature-artikel-mobile-published{
    display: none;
    font-size: 16px;
    line-height: 36px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.badge-artikel-readmore{
  cursor: pointer;
  background: #ED0089;
  color: #ffffff;
  font-size: 16px;
  border-radius: 30px;
  font-style: normal;
  font-weight: bold;
  width: 117px;
  height: 40px;
  text-align: center;
  padding-top: 7px;
  text-transform: none;
  margin-top: 20px;
}
.artikel-list-title{
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  padding-right: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-top: 5px;
  max-width: 32vw;
}
.artikel-list-cat{
  font-size: 18px;
  line-height: 24px;
  padding-right: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-top: -10px;
}
.artikel-list-desc{
  font-size: 18px;
  line-height: 24px;
  padding-right: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-top: 5px;
}

.feature-artikel{
  padding: 1vw;
  cursor: pointer;
  .nopadding{
    padding: 0 !important;
    margin: 0 !important;
  }
  .feature-artikel-thum{
    padding-left: 4vw;
    img{
      width: 35vw;
      height: 45vh;
      object-fit: cover;
    }
  }
  .feature-artikel-title{
    font-weight: bold;
    font-size: 40px;
    line-height: 45px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    padding-top: 30px;
    // max-width: 35vw;
    // max-height: 4em;
  }
  .feature-artikel-desc{
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    padding-right: 1em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-width: 37vw;
    max-height: 2.7em;
    margin-top: 20px;
  }
}

@media(max-width: 500px) {
  .section-list-artikel{
    margin: 2em 0px !important;
  }

  .artikel-list-thum{
    display: none !important;
  }
  .artikel-list-thum-mobile{
    display: block !important;
    margin-bottom: 1em;
  }
  .artikel-list-content{
    display: flex !important;
    padding-left: 0px !important;
  }
  .artikel-list-title{
    padding-right: 0px !important;
    -webkit-line-clamp: 1 !important;
    max-width: 95vw;
  }
  .artikel-list-desc{
    padding-right: 0px !important;
    -webkit-line-clamp: 4 !important;
    max-width: 95vw;
  }
  .badge-artikel-readmore{
    margin-top: 2vh !important;
    width: 80% !important;
    display: block;
    margin: auto;
  }
  .master{
    padding: 0 !important;
    margin: 0 !important;
  }
  .section-wrapper-artikel{
    padding-right: 5vw !important;
  }
  .feature-artikel{
    display: none;
  }
  .feature-artikel-mobile-thum{
    display: block !important;
    // margin-bottom: 1em;
  }
  .feature-artikel-mobile-content{
    display: flex !important;
    padding-left: 0px !important;
  }
  .feature-artikel-mobile-title{
    display: block !important;
    // margin-bottom: 1em;
  }
  .feature-artikel-mobile-published{
    display: block !important;
    // margin-bottom: 1em;
  }
  .border-artikel{
    border-top: 8px solid #323232 !important;
  }
}

