.menu-list-group{
  padding-top: 5px;
}
.iconUser{
  width: 20px;  
}
.hide{
  background-color: transparent!important; 
  transition: background-color 0.2s linear;

  }

.shows{
    /* background-color: #ECCC2F!important; */
    transition: background-color 0.2s linear;

  }

  .container-navbar {
    background-repeat: no-repeat;
    width: 100%;
    position: fixed;
    top: 0%;
    height:61px;;
    /* border-bottom: 8px solid #222; */
    z-index: 1000;
}


.container-navbar .latest-landing-header {
  
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding:1em 4em;
  position: relative;
  width: 100%;
  /* background-color: #ECCC2F; */
  height:61px;
  
}

.link{
  cursor: pointer;
  position: relative;
}

#sub .showMenu{
  display: flex!important;
}
.container-navbar .hideMenu {
  background-color: transparent; 
  transition: background-color 0.2s linear;
}

.container-navbar .showMenu {
  background-color: #ECCC2F!important;
  transition: background-color 0.2s linear;
}

#sub .hideMenu{
  display: none!important;
}

.container-navbar .sub-menu{
  display: flex;
  position: relative;
  background-color: white!important;
  height: 50px;

  align-items: center;
  align-content: center;
  width: 100vw;
}

/* .button-sosmed img{
  width: 20px;
}

.button-sosmed{
  margin-right: 20px
} */