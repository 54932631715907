.section-button-download{
    margin-bottom: 4.5rem;
    /* padding-top: 30px; */
}

.gap-download{
    padding-top: 1px;
}

.parallax-container{
    overflow: hidden;
    height: 70vh;
}

.video-featured-container{
    width: 50vw;
    height: 15vw;
    background-color: #757575;
    margin-right: 18px;
    background-size: cover;
    background-repeat: no-repeat;
}

.slider-vindes{
    margin-top: 0px!important;
    position: relative;
    z-index: 200;
    margin-bottom: 7rem;
}

.see-more-pink{
    color: #ED0089!important;
    align-items: center!important;
}
.video-detail-container{
    /* min-width: 30vw; */
}
.video-latest-container{
    width: 20vw;
    height: 10vw;
    background-color: #757575;
    margin-right: 18px;
    background-size: cover;
    background-repeat: no-repeat;
    
}
.video-section-latest {
    margin-left: 40px;
    border-left: #757575;
    border-width: 1px;
    padding-left: 38px;
    border-left-style: solid;
    
}
.gallery-list{
    background-color: #757575;
    border-top-left-radius: 10%;
    border-top-right-radius: 10%;
    min-width: 20vw;
    min-height: 22vw;
    background-repeat: no-repeat;
    background-size: cover;
}
.shop-list{
    background-color: #757575;
    width: 20vw;
    height: 25vw;
    background-repeat: no-repeat;
    background-size: cover;
    
}

.title-product{
    margin-top: 10px;
    font-size: 14px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    width: 245px;
}

.price-product{
    margin-top: 10px;
    text-decoration-line: line-through;
    color: #888888;

}

.price-disc-product {
    margin-top: 10px;
    font-weight: bold;

}
.video-slider{
    width: 100%;
}
.item {
    width: auto;
    height: auto;
}
.latest-landing-header.drop{
    top:61px;
    background-color: white;
    height: 50px;
}
.navbar-user{
    /* background-color: red; */
}

.link-button{
    position:relative;

}

.product-list{
    background-color: #757575;
    width: 20vw!important;
    height: 25vw!important;
    background-repeat: no-repeat;
    background-size: cover;
}

.container-section {
    height: auto;
    padding-left: 5vw;
    padding-right: 5vw ;
    padding-top: 5vw;
}

.container-section-program {
    margin-top: 9vw;
    margin-bottom: 9vw;
    margin-left: 3.5vw;
    margin-right: 3.5vw ;
    position: relative;
    min-height: 400px;;
}
.container-section-art {
    margin-bottom: 9rem;
    padding-left: 5vw;
    padding-right: 5vw;
    position: relative;
    /* min-height: 400px; */
    /* padding-top: 5vw; */
}

.container-section-program h3{
    margin-left: 1.5vw;
}

.container-title{
    padding-top: 1vw;
    margin-bottom: 27px;
    font-family: 'bold';
}
.title-art h3{
    margin-left:0!important
}
.container-art{
    /* margin-top: 200px; */
    min-height: 250px;
    position: relative;
    margin-left:1.51vw!important;

}

.mobile-art-detail-container{
    width: 70%;
}

.mobile-art-detail-container p{
    width: 100%;
    margin-bottom: 0px;
}

.art-slider-container{
    width: 100%;
    height: 100%;
   
}


.container-detail-art{
    margin-left: .5vw;
}



.container-inner-art{
    flex-direction: column;
    display: flex;
    justify-content: space-between;
}

.container-inner-art h2{
    font-size: 1.2rem!important;
}

.landing-slider-mobile-wrapper .container-inner-art{
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.banner-art{
    width: 20vw;
    height: 20vw;
    background-color: #888888;
    background-repeat: no-repeat;
    background-size: contain;
}

.banner-art-mobile{
    width: 29.2vw;
    height: 29.2vw;
    background-repeat: no-repeat;
    background-size: contain;
}

.image-title{
    height: 5em;
}



.background-art-desktop {
    
    position: absolute;
    height: 150%;
    top: -30px;
    width: 100vw;
    z-index: -1;
    left: 0vw;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: -10vh;
}

.background-promo-desktop {
    position: absolute;
    height: 150%;

    width: 100vw;
    z-index: -1;
    left: 0vw;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: -10vh;
}

.background-merch-desktop {
    background-color: #ED0089;
    position: absolute;
    height: 130%;
    top: -7%;
    width: 100vw;
    left: 0vw;
    z-index: -1;

    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: -10vh;

}



.background-art-mobile {
    
    position: absolute;
    height: 160%;
    top: -30px;
    width: 100vw;
    z-index: -1;
    left: 0vw;
    background-repeat: no-repeat;
    background-position-y: -10vh;
}

.background-promo-mobile {
    
    position: absolute;
    height: 150%;
    top: -20px;
    width: 100vw;
    z-index: -1;
    left: 0vw;
    background-repeat: no-repeat;
    background-position-y: -10vh;
}

/* .active::before {
    content: "";
    border-bottom: 7px solid white;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    position: absolute;
    bottom: -22px;
    left: 50%;
    z-index: 10;
} */

.hidden {
    display:none!important;
 }
 .show{
    display:block;
 }

#scene .tv {
    width: 10vw;
    height: auto;
    position: absolute;
   
    transition: 0.3s;
    right: 10vw!important;
    left: 70vw!important;
    top: 50vh!important;
}
#scene .vincen {
    width: 40vh;
    height: auto;
    position: absolute;
   
    transition: 0.3s;
    right: 10vw!important;
    left: 10vw;
    top: 60vh;
}
#scene .desta {
    width: 40vh;
    height: auto;
    position: absolute;
   
    transition: 0.3s;
    right: 10vw!important;
    left: 60vw;
    top: -10vh;
}
#scene .vindes-box {
    width: auto;
    height: 50vw;
    position: absolute;
    transition: 0.3s;
    top: -5vh!important;
}
#tv-hover {
    
}

.overlay{
    background-color: transparent;
    width: 20vw;
    height: 20vw;
    position: absolute!important;
    transition: 0.3s;
    right: 14vw!important;
    left: 68vw!important;
    top: 50vh!important;
    z-index: 300;
}

.overlay-vincen{
    background-color: transparent;
    width: 20vw;
    height: 23vw;
    position: absolute!important;
    transition: 0.3s;
    right: 14vw!important;
    left: 10vw!important;
    top: 52vh!important;
    z-index: 300;
}

.overlay-desta{
    background-color: transparent;
    width: 24vw;
    height: 23vw;
    position: absolute!important;
    transition: 0.3s;
    right: 14vw!important;
    left: 62vw!important;
    top: -10vh!important;
    z-index: 500;
}

.overlay-vincen:hover ~ #scene .vincen{
    /* width: 10.3vw!important; */
    height: auto!important;
    transform: rotate(10deg)!important;
    /* transform: rotate(10deg)!important; */
    
}

.overlay-desta:hover ~ #scene .desta{
    /* width: 10.3vw!important; */
    height: auto!important;
    
    transform: rotate(10deg)!important;
   
    
}

.overlay-vincen:hover + #scene .tv{
    width: 10.3vw!important;
    height: auto!important;
    transform: rotate(30deg)!important;
    
}

.container{
    margin-top: 40px;
    min-width: 90vw;
    height: auto;
    /* background-color: green; */
    align-items: center;
    justify-content: center;
    justify-items: center;
    position: relative;
}

.container .next-arrow{
    display: flex;
    align-items: center;
    background-color: white;
    height: 100%;
    width: 100px;
    position: absolute;
    right: 0px;
    top: 0%;
    z-index: 10;
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(254,254,254,1) 64%);
}
.container .next-arrow:hover{
    cursor: pointer;
}

.container .next-arrow img{
    height: 40px;
    margin-left: 70px;
}

.input-square{
    padding-left: 15px;
    border: none;
}

.video-container {
    
    width: 24vw;
    height: 15vw;
    background-color: gray;
    background-size: cover;
}

.list-content{
    width: 100%;
    margin-bottom: 6rem;
    position: relative;
    z-index: 200;
   
}

.banner-text-container {
    position: absolute;
}

/* .overlay{
    
    height: 100vh;
    width: 100%;
    z-index: 1000;
    position: absolute;
  } */

  .overlay div{
    background-color: transparent;
    height: 100%;
    width: 100%;
    z-index: 0;
    position: relative;
  }

.latest-landing-sec {
    margin-top: 0px;
    background-repeat: no-repeat;
    height: 100%;
    display: flex;
    background-position: center;
    align-items: center;
    justify-content: center;
    background-size: cover;
    position: relative;
    /* background-attachment: fixed; */
    z-index: 0;
    /* position: relative; */
    height: 100vh;
    /* border-bottom: 8px solid #222; */
}

.banner-image{
    position: absolute;
    width: 60vw;
    height: auto;
    z-index: 0;
}   

/* .latest-landing-sec:before{
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
} */



.latest-landing-sec .new-logo {
    height: 3em;
    width: auto;
}

.latest-landing-sec .signin-btn {
    background-color: #e50914;
    line-height: normal;
    padding: 7px 17px;
    font-weight: 400;
    font-size: 1rem;
    color: #fff;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

.latest-banner-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.banner-theme-form {
    display: flex;
}

.banner-theme-form .form-control {
    min-width: 450px;
    height: 60px;
    -webkit-border-bottom-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    border-bottom-right-radius: 0;
    -webkit-border-top-right-radius: 0;
    -moz-border-radius-topright: 0;
    border-top-right-radius: 0;
    font-size: 1.1em;
}

.theme-form-sec {
    position: relative;
}

/* .banner-theme-form .form-label {
    position: absolute;
    color: #8c8c8c;
    font-size: 1.1em;
    font-weight: 600;
    z-index: 1;
    top: 18px;
    left: 10px;
   display: none;
} */

.sign-group{
    height: 100%;
}


.banner-theme-form .form-control:focus::-webkit-input-placeholder {
    font-size: .75em;
    position: relative;
    top: -20px; 
}

.banner-theme-form .form-control:focus .banner-theme-form .form-control {
    font-size: .75em;
}

.banner-theme-form .form-control:focus .banner-theme-form .form-label{
    top: -20px!important; 
}

.banner-theme-form .form-control::placeholder{
    color: #8c8c8c;
    font-size: 1em;
    font-weight: 600;
}

.banner-theme-form .btn.btn-search {
    font-size: 1.625rem;
    min-height: 60px;
    margin: 0!important;
    -webkit-border-bottom-left-radius: 0;
    -moz-border-radius-bottomleft: 0;
    border-bottom-left-radius: 0;
    -webkit-border-top-left-radius: 0;
    -moz-border-radius-topleft: 0;
    border-top-left-radius: 0;
    border-left: 1px solid #333;
    color: #fff;
    background-color: #e50914;
    background-image: -webkit-gradient(linear,left top,left bottom,from(#e50914),to(#db0510));
    background-image: -webkit-linear-gradient(top,#e50914,#db0510);
    background-image: -moz- oldlinear-gradient(top,#e50914,#db0510);
    background-image: -o-linear-gradient(top,#e50914,#db0510);
    background-image: linear-gradient(to bottom,#e50914,#db0510);
    -webkit-box-shadow: 0 1px 0 rgba(0,0,0,.45);
    -moz-box-shadow: 0 1px 0 rgba(0,0,0,.45);
    box-shadow: 0 1px 0 rgba(0,0,0,.45);
    padding: 0 1em;
}

.latest-banner-content-info {
    text-align:center;
    max-width: 640px;
    margin: 0 auto;
}

.latest-banner-content-info .banner-title{
    font-size: 3.5em;
    line-height: 1.1;
    font-weight: 600;
    margin-bottom: 20px;
}

.latest-banner-content-info .banner-subtitle {
    font-size: 1.7em;
    margin-bottom: 20px;
}

.latest-banner-content-info .banner-desc {
    font-size: 1.2em;
    font-weight: 500
}

/*Latest About Section CSS*/

.latest-landing-about-sec {
    /* background-color: #000000; */
    border-bottom: 8px solid #222;
    padding: 60px 45px;
}

.latest-landing-about-sec .tv-img {
   width: 100%; 
   margin-top: -3em;
}

.latest-landing-about-sec .about-details {
    padding-top: 7.5em;
}

.latest-landing-about-sec .about-details .about-title{
    font-size: 3.3rem;
    line-height: 1.1;
    font-weight: 700;
    margin: 0 0 .4em;
}

.latest-landing-about-sec .about-details .about-desc{
    font-size: 1.625rem;
    font-weight: 500;
    line-height: 1.4;
    margin: .75em 0 .25em;
}

.latest-landing-about-sec .tv-img-sec {
    position: relative;
    z-index: 2;
}

.latest-landing-about-sec .about-video-sec {
    width: 100%;
    height: 100%;
    max-width: 70%;
    max-height: 69%;
    position: absolute;
    top: 46%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}
.our-about-card-video {
    width: 100%;
}

/*Latest Download section CSS*/

.latest-download-sec{
    background-color: #000000;
    border-bottom: 8px solid #222;
    padding: 60px 45px;
    padding-top: 20px;
}

.latest-download-sec .mobile-img {  
    width: 100%;
    margin-left: -6em;
 }
 
 .latest-download-sec .download-details {
     padding-top: 9em;
 }
 
 .latest-download-sec .download-details .download-title{
     font-size: 3.3rem;
     line-height: 1.1;
     font-weight: 700;
     margin: 0 0 .4em;
 }
 
 .latest-download-sec .download-details .download-desc{
     font-size: 1.625rem;
     font-weight: 500;
     line-height: 1.4;
     margin: .75em 0 .25em;
 }
 
 .latest-download-sec .mobile-img-sec {
     position: relative;
 }

.our-download-card {
    border: 2px solid rgba(255,255,255,.25);
    -webkit-box-shadow: 0 0 2em 0 #000;
    -moz-box-shadow: 0 0 2em 0 #000;
    box-shadow: 0 0 2em 0 #000;
    width: 60%;
    min-width: 15em;
    -moz-border-radius: .75em;
    border-radius: .75em;
    padding: .5em .75em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    left: 35%;
    bottom: 8%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    background-color: #000000;
}

.book-img {
    height: 6em;
    object-fit: cover;
}

.our-download-card-image{
    margin: 0 1em 0 0;
}

.our-download-card .our-download-card-text .download-sub-title{
    font-size: 1.1em;
    color: #fff;
    font-weight: 600;
    letter-spacing: 0.02em;
    margin-bottom: 0;
}

.our-download-card .our-download-card-text .download-sub-desc{
    font-size: 1em;
    color: #0071eb;
    font-weight: 400;
    margin-bottom: 0;
}

.our-download-info {
    display: flex;
    align-items: center;
}

.download-gif-img-sec .download-gif-img{
    max-width: 3.5em;
}

/*Latest Watch Everywhere Section CSS*/

.latest-watch-everywhere-sec {
    background-color: #000000;
    border-bottom: 8px solid #222;
    padding: 60px 45px;
}

.latest-watch-everywhere-sec .all-device-img {
   width: 100%; 
}

.latest-watch-everywhere-sec .watch-everywhere-details {
    padding-top: 9em;
}

.latest-watch-everywhere-sec .watch-everywhere-details .watch-everywhere-title{
    font-size: 3.3rem;
    line-height: 1.1;
    font-weight: 700;
    margin: 0 0 .4em;
}

.latest-watch-everywhere-sec .watch-everywhere-details .watch-everywhere-desc{
    font-size: 1.625rem;
    font-weight: 500;
    line-height: 1.4;
    margin: .75em 0 .25em;
}

.latest-watch-everywhere-sec .all-device-img-sec {
    position: relative;
    z-index: 2;
}

.latest-watch-everywhere-sec .watch-everywhere-video-sec {
    width: 100%;
    height: 100%;
    max-width: 61%;
    max-height: 76%;
    position: absolute;
    top: 46%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}
.our-watch-everywhere-card-video {
    width: 100%;
}

/*Faq Section CSS*/

.latest-faq-section {
    padding: 70px 45px;
    position: relative;
    border-bottom: 8px solid #222;
    padding: 50px 5%;
    margin-bottom: 0;
    background: 0 0;
    color: #fff;
    background-color: #000000;
}

.latest-faq-section .section-title {
    font-size: 3.3rem;
    line-height: 1.1;
    font-weight: 700;
    color: #ffffff;
    text-align: center;
}

.faq-lists-sec .accordion {
    width: 75%;
    margin: 4em auto;
    max-width: 815px;
}

.faq-lists-sec .accordion .card-header{
    background: #303030;
    padding: .8em 2.2em .8em 1.2em;
}

.faq-lists-sec .accordion .card {
    border:0;
    background-color: #000000;
    margin-bottom: 1em;
}

.faq-lists-sec .accordion .card .card-body {
    background: #303030;
    display: inline-block;
    padding: 1.2em;
}
.faq-lists-sec .accordion .card .card-body p{
    font-size: 1.6em;
    color: #ffffff;
    font-weight: 500;
    padding: 0 0.5em;
}

.faq-lists-sec .accordion .card .heading-title {
    font-size: 0.9em;
    color: #ffffff;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
}

.form-align-center {
    justify-content: center;
}

.latest-banner-content-info-form .faq-desc{
    font-size: 1.2em;
    font-weight: 500;
    text-align: center;
}

/*Footer Section CSS*/
.footer-sec{
    background-color: #FEFDED;
}

.footer-sec .footer-sec-card {
    padding: 70px 45px;
}

.footer-sec .footer-sec-card .footer-site-sec {
    margin: 0 auto;
    padding-top: 30px;
    width: 90%;
}

.footer-top-title {
    padding: 0;
    margin: 0 0 30px;
    font-size: 1em;
    color: #757575;
}

.footer-link-item {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
    margin-bottom: 16px;
    display: inline-block;
    min-width: 100px;
    width: 25%;
    padding-right: 12px;
    vertical-align: top;
    color: #757575;
    font-size:1em;
}

.language-dropdown {
    text-indent: 0;
    padding: 0.8em;
    background-image: none;
    border: 1px solid #333;
    color: #999;
    font-size: 16px;
    background-color: transparent;
}

.footer-company-name {
    color: #757575;
    font-size:1em;
    margin-bottom: 0;
    padding-top: 1em;
}

.heading-title.collapsed::after {
    content: "\002B";
    font-size: 2em;
    position: absolute;
    right: 2rem;
}

.heading-title::after {
    content: "\2212";
    font-size: 2em;
    position: absolute;
    right: 2rem;
}

.title-order-merch-select{
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
}

.title-order-merch-select-by{
    margin: auto 15px auto auto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 40px;
}
.select-merch-form-control{
    margin: auto 0px;
}

.select-merch-form-control-by{
    border-radius: 60px;
    height: 33px !important;
    width: 141px;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* background-position-x: calc(100% - 10px) !important;
    background-position-y: center !important;
    background-size: calc(100% - 10px);
    background-repeat-x: no-repeat !important;
    background-repeat-y: no-repeat !important;
    background-attachment: initial !important;
    background-origin: initial !important;
    background-clip: initial !important;
    background-color: white !important;
    color: #000000 !important;
    outline: none !important;
    box-shadow:none !important; */
    /* background: url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png") white no-repeat calc(100% - 10px) !important; */
}
