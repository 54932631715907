.art-dark .modal-content{
    background-color: #212529;
}
.art-dark{
    border: none;
}

.gallery-item {
    border-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    margin-bottom: 5px;
}
.audio-item{
    background-color: grey;
    width: 100%;
    height: 100px;
    border-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    margin-bottom: 5px;
    position: relative;
}

.audio-item audio{
    max-width: 15vw!important;
}
.video-item{
    border-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    margin-bottom: 5px;
    position: relative;
    display: flex;
}
.video-item img{
    border-radius: 30px;
}
.overlay-video{
    border-radius: 30px;
    width: 100%;
    height:100%;
    position: absolute!important;
    background-color: rgba(0, 0, 0, 0.315);
    z-index: 100;
}
.gallery-item-wrapper{
    position: relative;
}
.overlay-audio{
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.37);
    border-radius: 30px;
    /* margin-bottom: 5px; */
}
.image-placeholder-art{
    display: flex;
    width: 100%;
    height: 200px;
    position: relative;
    background-color: rgba(0, 0, 0, 0.37);
    border-radius: 30px;
}
.audio-icon{
    /* height: 50px; */
    color: rgba(255, 255, 255, 0.555);
    font-size: 96px;
}
.gallery-item-container {
    margin: 10px;
    margin-bottom: 20px;
}

.count-gallery-item{
    color: grey;
}

.section-name-countgallery{
    margin-top: 1em;
    font-size: 14px;
}

.see-more-pink{
    color: #ED0089!important;
    align-items: center!important;
}


.gallery-item-container:hover {
    cursor: pointer ;
}

.ribbon-menu {
    /* background-color: red; */
    height: 100px;
    width: 100%;
}

#slider-karya{
    width: 100vw;
    height: 300px;
}

.container-slider{
    /* height: 300px; */
    /* margin-top:62px; */
   
}
.slider-content{
    /* background-color: silver; */
}
#next-arrow, #prev-arrow{
    position: absolute;
    top: 100px;
}

#prev-arrow {
    left: 0px;
    z-index: 100;
}

#next-arrow{
    right: 0px;
    
}


.title-creation-section{
    margin: 0px 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 31px;

}

.filter-container-creation-section-wrapper{
    margin-bottom: 2em;
}

.filter-container-creation-section{
    width: 90vw;
    overflow-y: hidden;
}

.badge-creation-section{
    cursor: pointer;
    background: #F67DC3;
    color: #ffffff;
    font-size: 16px;
    border-radius: 30px;
    font-style: normal;
    font-weight: bold;
    min-width: 117px;
    height: 40px;
    text-align: center;
    padding-top: 7px;
    text-transform: none;
    margin: 0px 10px;
}

.badge-creation-section-active{
    cursor: pointer;
    background: #ED0089;
    color: #ffffff;
    font-size: 16px;
    border-radius: 30px;
    font-style: normal;
    font-weight: bold;
    min-width: 117px;
    height: 40px;
    text-align: center;
    padding-top: 7px;
    text-transform: none;
    transition: .5s;
    /* transform: scale(1.1); */
    margin: 0px 10px;
}


.section-wrapper-art-page{
    width: 100%;
    position: relative;
    z-index: 200;
    ;
}


.section-wrapper-slider{
    margin-top: 61px;
}

.section-wrapper-art{
    padding-left: 5vw;
    padding-right: 5vw;
    padding-top: 8em;
}

