.slider-category {
  // display: flex;
  position: relative;
  &__container {
    display: flex;
    padding: 0 25px;
    transition: transform 300ms ease 100ms;
    z-index: 3;
    width: 100%;
  }

  &:not(&--open) .item-cetegory:hover .show-details-button {
    opacity: 1;
  }

  &:not(&--open) .item-cetegory:hover {
    transform: scale(1.1) !important;
  }

  &:not(&--open):hover .item-cetegory {
    transform: translateX(-10%);
  }

  &:not(&--open) .item-cetegory:hover ~ .item-cetegory {
    transform: translateX(10%);
  }
}
