
.tv {
  width: 10vw;
  height: auto;
  position: absolute;
  right: 20%;
  top: 65%;
  transition: 0.3s;
}

.parallax-sec {
  height: 40vh!important;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
}
.toko-box{
  /* width: 20vw; */
  height: auto!important;
  top: 0px;
}

.container-merch-mobile .parallax-sec {
  height: 40vh!important;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-merch-mobile .toko-box{
  height: 30vw!important;
  width: auto!important;
  top: 0px;
  justify-content: center;
  
}