@media (max-width: 900px) {
  .item-mobile {
      display: block!important;
  }
  .item-desktop{
    display: none!important;
  }
  .container-button-art {
    img{
      height: 30px!important;
    }
  }
  .wrapper-button-storeprefix-share {
    width: 30px!important;
    height: 30px!important;
  }
  .wrapper-button-art-share {
    position: relative;
    background: #FFFFFF;
    border-radius: 60px;
    width: 30px!important;
    height: 30px!important;
    color: #222222;
    cursor: pointer;
  }
  .title-wrapper-storeprefix {
    font-size: 22px !important;
    line-height: 24px !important;
  }
}
@media (min-width: 901px){
  .item-mobile {
    display: none!important;
  }
  .item-desktop{
    display: block!important;
  }
}
.wrapper-button-art-share {
  position: relative;
  background: #FFFFFF;
  border-radius: 60px;
  width: 40px;
  height: 40px;
  color: #222222;
  // margin-right: 1em;
  cursor: pointer;

  
}
.modal-storeprefix-wrapper {
  // top: 61px;
  animation: fly .4s ease-in;
  overflow: auto;
  padding-right: 0 !important;
  padding-left: 0 !important;

  .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    max-width: 100%;
  }

  .modal-content {
    height: auto;
    min-height: 100%;
    background: transparent;
  }

  .modal-header {
    border-bottom: none;
    padding: 10px 15px;
    .close {
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      line-height: 40px;
      color: #ffffff;
      outline: none;
      border: none;
      opacity: 1;
    }

  }

  .modal-body {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px 20px 0px 0px;
    background: #212529;
    height: 50vh;
    overflow: auto;
  }

  .wrapper-storeprefix {
    margin: 2em 5vw;
    .wrapper-video-storeprefix{
      width: 100%;
      height: 100%;
    }
    .wrapper-audio-storeprefix{
      width: 100%;
      height: 100%;
    }
    .wrapper-image-storeprefix{
      position: relative;
      img{
        width: 100%;
        height: 100%;
      }
      .wrapper-audio-art {
        width: 100%;
        height: 40vh;
      }
      .icon-image-storeprefix-zoom{
        position: absolute;
        top: 15px;
        right: 25px;
        height: 2.85em;
        width: 2.85em;
        border-radius: 50%;
        background-color: #00000090;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        cursor: pointer;
      }
    }
    .wrapper-conten-storeprefix {
      margin-top: 2em;
      .title-wrapper-storeprefix {
        font-weight: bold;
        font-size: 30px;
        line-height: 35px;
      }
      .wrapper-flex-storeprefix-button-text {
        display: flex;
        margin-top: 2em;
        margin-bottom: 1.5em;
        .date-storeprefix-button {
          margin: auto auto auto 0px;
          font-size: 14px;
          line-height: 20px;
        }
        .wrapper-button-storeprefix {
          position: relative;
          background: #ED0089;
          border-radius: 60px;
          width: 100px;
          height: 38px;
          cursor: pointer;
          .container-button-storeprefix {
            position: absolute;
            left: 50%;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            display: flex;
            .child-icon-buton-storeprefix {
              font-size: 20px;
            }
            .child-text-button-storeprefix {
              font-size: 15px;
              line-height: 18px;
              margin-left: 7px;
              margin-top: 2px;
              font-weight: bold;
            }
          }
        }
      }
      .wrapper-button-storeprefix-share {
        position: relative;
        background: #ED0089;
        border-radius: 60px;
        width: 116px;
        height: 38px;
        color: #ffffff;
        cursor: pointer;
        .container-button-storeprefix {
          position: absolute;
          left: 50%;
          top: 50%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          display: flex;
          .child-icon-buton-storeprefix {
            font-size: 20px;
          }
          .child-text-button-storeprefix {
            font-size: 15px;
            line-height: 18px;
            margin-left: 7px;
            margin-top: 4px;
            font-weight: bold;
          }
        }
      }
      .desc-wrapper-storeprefix {
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;
        border-width: 1px;
        border-top: #CCCCCC;
        border-bottom: #CCCCCC;
        border-top-style: solid;
        border-bottom-style: solid;
        padding: 20px 0px 30px 0px;
        margin: 1em 0;
        .wrapper-avatar-storeprefix-seder{
          display: flex;

          .avatar-storeprefix-seder{
            background-image: url('https://upload.wikimedia.org/wikipedia/id/d/d5/Aang_.jpg');
            width: 46px;
            height: 46px;
            background-size: cover;
            background-position: center center;
            border-radius: 50%;
          }
          .avatar-storeprefix-seder-user{
            margin-top: 0.5em;
            margin-left: 1em;
            font-weight: bold;
            font-size: 14px;
            line-height: 17px;
          }
        }
      }
    }
  }
  .modal-storeprefix-title-buy{
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 40px;
  }
  .container-modal-buttons-detail-storeprefix{
    flex-wrap: wrap;
    margin-top: 2em;
    .button-modal-storeprefix{
      background-color: #ffffff;
      cursor: pointer;
      height: 74px;
      border-radius: 50px;
      margin-bottom: 20px;
      justify-content: center;
      display: flex;
      align-items: center;
      img{
        height: 50px;
      }
  }
    
  }
}

.art-liked{
  color: #FFFFFF !important;
  background-position:right bottom !important;
}

.date-art-button {
  margin: auto auto auto 0px;
  font-size: 14px;
  line-height: 20px;
}

.wrapper-button-art {
  position: relative;
  // background: #fcd9ed;
  background: linear-gradient(to right, #fcd9ed 50%, #ED0089 50%);
  background-size: 200% 100%;
  background-position:left bottom;
  transition:all 1s ease;
  color: #ED0089;
  border-radius: 60px;
  width: 100px;
  height: 38px;
  cursor: pointer;

  .container-button-art {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: flex;
    img{
      height: 40px!important;
      border-radius: 50%;
    }
    .child-icon-buton-art {
      font-size: 20px;
    }

    .child-text-button-art {
      font-size: 15px;
      line-height: 18px;
      margin-left: 7px;
      margin-top: 2px;
      font-weight: bold;
    }
  }
}