.gallery-item {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    margin-bottom: 5px;
}
.gallery-item-wrapper{
    position: relative;
}
.overlay-audio{
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.37);
    border-radius: 30px;
    /* margin-bottom: 5px; */
}
.audio-icon{
    color: rgba(255, 255, 255, 0.555);
    font-size: 96px;
}
.gallery-item-container {
    margin: 10px;
    margin-bottom: 20px;
}

.section-name-audio{
    margin-top: 1em;
    font-size: 17px;
    font-weight: bold;
}

.section-title-audio{
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis!important;
    white-space: nowrap;
}

.gallery-item-container:hover {
    cursor: pointer ;
}

.section-wrapper-audio-page{
    width: 100%;
    position: relative;
    z-index: 200;
}

.section-wrapper-audio{
    padding-left: 5vw;
    padding-right: 5vw;
    padding-top: 8em;
}

