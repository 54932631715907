.title-category{
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 38px;
    margin-left: 4%;
    padding-top: 25px;
}

.breadcumb-program{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    margin-left: 4%;
}