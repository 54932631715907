
.iconUser{
  width: 20px;  
}

.showMenu{
  display: flex;
}

.hideMenu{
  display: none!important;
}

#contentNav:hover ~ .subMenu{
  display: flex!important;
}

.navbarHome {
  padding-top: 0px!important;
  padding-bottom: 0px!important;
}

.subMenu:hover{
  display: flex!important;
}

.subMenu{
  position: fixed;
z-index: 999999;
background-color: white ;
height: 50px;
align-items: center;
align-content: center;
width: 100vw;
top: 60px;
left: 0px;
}

.hide{
  background-color: transparent!important; 
  transition: background-color 0.2s linear;

  }

.buttonSignup{
  font-size: 15px;
  background-color: transparent;
  color: white;
  font-weight: bold;
  margin-left: 20px;
  margin-right: 10px;
}

.shows{
    background-color: black!important;
    transition: background-color 0.2s linear;

  }