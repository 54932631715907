.container-treamandcondition{
  padding-top: 6em;
  padding-left: 5vw;
  padding-right: 5vw;
  h1{
    margin-bottom: 1em;
  }
  a{
    color:#ED0089;
  }
  .btn-back-treamandcondition{
    margin: 1em 0px;
    width: 210px;
    padding: 5px;
    background: #ED0089;
    font-size: 1.5rem;
    float: right;
    text-align: center;
    cursor: pointer;
    color: #ffffff;
    font-weight: bold;
  }
}