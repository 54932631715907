.modal-artikel-wrapper {
    animation: fly .4s ease-in;
    overflow: auto;
    padding-right: 0 !important;
    padding-left: 0 !important;   
    .modal-dialog {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      max-width: 100%;
    }
    .modal-content {
      height: auto;
      min-height: 100%;
      background: transparent;
    }
    .modal-header {
      border-bottom: none;
      padding: 10px 15px;
      .close {
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 40px;
        color: #ffffff;
        outline: none;
        border: none;
        opacity: 1;
      }
    }
    .modal-body {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 20px 20px 0px 0px;
      background: #212529;
      height: 50vh;
      overflow: auto;
    }
    #article{
      width: 100%;
      margin: 0 auto;
      position: relative;
      overflow: hidden;
      display: block;
      font-size: 16px;
      color: #ffffff;
      line-height: 24px;
      .entry-article{
        width: 100%;
        float: left;
        clear: both;
        padding: 0 0 24px 0;
        .inner-article{
          max-width: 1080px;
          margin: 0 auto;
          overflow: hidden;
          display: block;
          padding: 0 24px;
          .wrapper-artikel{
            max-width: 100%;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            padding-left: 10px;
            padding-right: 10px;
            .title-artikel{
              font-weight: bold;
              font-size: 35px;
              line-height: 48px;
              color: #ffffff;
              padding: 1vw 0vw;
            }
            .published-artikel{
              font-size: 20px;
              line-height: 24px;
              color: #ffffff;
              padding-bottom: 1vw;
            }
            .image-artikel {
              padding-bottom: 1vw;
              img{
                width: 100% !important;
                height: auto;
              }
            }
            .content-artikel {
              display: flex;
              flex-flow: row nowrap;
              img{
                width: 100% !important;
                height: auto !important;
              }
              iframe{
                width: 100%;
                height: 513px;
                left: 0px;
                top: 0px;
              }
              ul.content-sosmed {
                font-size: 24px;
                float: left;
                list-style: none;
                padding: 6px 0px 0 24px;
              }
              ul.content-sosmed img{
                width: 40px !important;
              }
              ul.content-sosmed li{
                padding-bottom: 15px;
              }
              ul.content-sosmed li .wrapper-button-art{
                width: 40px;
              }
              ul.content-sosmed li .wrapper-button-art .container-button-art .child-icon-buton-art{
                font-size: 14px;
                margin: auto;
              }
              ul.content-sosmed li .wrapper-button-art .container-button-art .child-text-button-art{
                font-size: 13px;
                margin-left: 3px;
              }
              .single-artikel {
                float: left;
                padding: 0 24px;
                overflow: hidden;
                width: 100%;
                position: relative;
                p {
                  text-align: justify;
                }
                a {
                  color: #1F98CE;
                }
              }
          }
        } 
      }
      }
    }
}

@media(max-width: 500px) {
  .title-artikel{
    font-size: 24px !important;
    line-height: 30px !important;
    padding-bottom: 15px !important;
  }
  .published-artikel{
    font-size: 16px !important;
    line-height: 20px !important;
    padding-bottom: 15px !important;
  }
  .content-artikel iframe{
    height: 215px !important;
  }
  ul.content-sosmed{
    display: none;
  }
  .single-artikel {
    padding: 20px 0px !important;
  }
  .wrapper-button-art{
    height: 30px !important;
    width: 45px !important;
  }
  .wrapper-button-art .container-button-art .child-icon-buton-art{
    font-size: 14px !important;
    margin: auto !important;
  }
  .wrapper-button-art .container-button-art .child-text-button-art{
    font-size: 13px !important;
    margin-left: 3px !important;
  }
}