@media (max-width: 575.98px) {
  .parallax-desktop{
    display: none;
  }

  .parallax-mobile{
    display: block;
  }
}

@media (min-width: 575.99px) {
  .parallax-desktop{
    display: block;
  }

  .parallax-mobile{
    display: none;
  }
}

.parallax-desktop{
  .tv {
    width: 10vw;
    height: auto;
    position: absolute;
    right: 20%;
    top: 65%;
    transition: 0.3s;
  }
  
  .vincen {
    width: 10vh;
    height: auto;
    position: absolute;
    right: 20%;
    top: 0vh!important;
    transition: 0.3s;
  }
  
  .layer1 {
    width: 100vw;
    top: -10%!important;
  }
  
  .fg1 {
    width: 100vw;
    top: -26%!important;
  }
  
  .fgJasiman{
    left: 15%!important;
    top: 13%!important;
  }
  
  .fgVindes{
    float: right;
    left: 62%!important;
    top: 68%!important;
  }
  
  .fgNgapain{
    left: 70%!important;
    top: 12%!important;
  }
  
  .fgC2{
    left: 10%!important;
    top: 62%!important;
  }
  
  .fg2u{
    left: 35%!important;
    top: 68%!important;
  }
  
  .fgMerch{
    left: 72%!important;
    top: 40%!important;
  }
  
  .fgArt{
    left: 75%!important;
    top: 60%!important;
  }
  
  .fgLogo{
    left: 50%!important;
    -webkit-transform: translateX(-50%)!important;
    transform: translateX(-50%)!important;
    top: 40%!important;
     transform: translate(-50%,-50%)!important;
    /* -webkit-transform: translateY(50%)!important;
    transform: translateY(50%)!important; */
  }
  
  .fgDesta{
    float: right;
    left: 57%!important;
    top: 43%!important;
  }
  
  .fgVincent{
    left: 18%!important;
    top: -10%!important;
  }
}



.parallax-mobile{
  .tv {
    width: 10vw;
    height: auto;
    position: absolute;
    right: 20%;
    top: 65%;
    transition: 0.3s;
  }
  
  .vincen {
    width: 10vh;
    height: auto;
    position: absolute;
    right: 20%;
    top: 0vh!important;
    transition: 0.3s;
  }
  
  .layer1 {
    height: 100vh;
  }
  
  .fg1 {
    position: absolute;
    height: 100vh;
    top: -12%!important;
  }
  
  .fgJasiman{
    width: 30%;
    position: absolute;
    left: 17%!important;
    top: 20%!important;
  }
  
  .fgVindes{
    width: 30%;
    position: absolute;
    float: right;
    left: 62%!important;
    top: 72%!important;
  }
  
  .fgNgapain{
    width: 30%;
    position: absolute;
    left: 65%!important;
    top: 17%!important;
  }
  
  .fgC2{
    width: 30%;
    position: absolute;
    left: 10%!important;
    top: 68%!important;
  }
  
  .fg2u{
    width: 30%;
    position: absolute;
    left: 35%!important;
    top: 75%!important;
  }
  
  .fgMerch{
    width: 30%;
    position: absolute;
    left: 75%!important;
    top: 40%!important;
  }
  
  .fgArt{
    width: 30%;
    position: absolute;
    left: 75%!important;
    top: 65%!important;
  }
  
  .fgLogo{
    position: absolute;
    left: 50%!important;
    -webkit-transform: translateX(-50%)!important;
    transform: translateX(-50%)!important;
    top: 40%!important;
     transform: translate(-50%,-50%)!important;
     width: 70%;
    /* -webkit-transform: translateY(50%)!important;
    transform: translateY(50%)!important; */
  }
  
  .fgDesta{
    width: 50%;
    position: absolute;
    float: right;
    left: 57%!important;
    top: 70vh!important;
  }
  
  .fgVincent{
    width: 50%;
    position: absolute;
    left: 17%!important;
  }
}
