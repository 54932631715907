
.slider {
  // display: flex;
  position: relative;

  &__container {
    display: flex;
    padding: 0 55px;
    transition: transform 300ms ease 100ms;
    z-index: 3;
    width: 100%;
  }

  &:not(&--open) .item:hover .show-details-button {
    opacity: 1;
  }

  &:not(&--open) .item:hover {
    transform: scale(1.1) !important;
  }

  &:not(&--open):hover .item {
    transform: translateX(-5%);
  }

  &:not(&--open) .item:hover ~ .item {
    transform: translateX(5%);
  }
}

.tes-element{
  width: 200px;
  height:400px;
  background-color: red;
}
.container-fakhri {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
