.slider-category-wrapper {
  // padding: 40px 0;
  overflow: hidden;
  position: relative;
}
.slider-category-conten-wrapper{
  margin-left: 55px;
  .container-slider-category-conten{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    overflow: hidden;
  }


  .slider-category-conten-img{
    width: 245px;
    background-color: #ECCC2F;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .slider-category-conten{
    padding: 0 25px;
    div{
      margin-top: 5px;
      font-weight: bold;
      font-size: 200%;
      padding-right: 15vw;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    p{
      margin: 1em 0px;
      padding-right: 15vw;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      font-style: normal;
      font-weight: normal;
      line-height: 23px;
      // color: #444444;
    }
  }
}
