
#slider-promo{
    width: 100%;
    height: 400px;
    
}

.slider-promo-categories{
    height: 70%;
    width: 95%
}

.item-promo-slider{
  background-color: #757575;
  width: 20vw!important;
  height: 25vw!important;
  background-repeat: no-repeat;
  background-size: cover;
}

.landing-slider-mobile-wrapper .item-promo-slider{
    background-color: #757575;
    width: 40vw!important;
    height: 45vw!important;
    background-repeat: no-repeat;
    background-size: cover;
  }

.list-container-promo-slider{
  display: flex!important;
  justify-content: center!important;
}

.container-promo-slider{
    max-width: 50%!important;
}

.container-slider-promo{
    /* height: 300px; */
    /* margin-top:62px; */
    width: 100%;
    height: 320px;
}
.container-promo{
    display: flex!important;
    flex-direction: column;
    justify-content: center!important;
   
}
.container-badge{
    width: 100%;
    justify-content: center;
    display: grid;
}
.container-inside{
    width: 20vw;
    display: block;
}

.landing-slider-mobile-wrapper .container-inside{
    width: 40vw;
    display: block;
}
.badge-promo{
    background-color: #F67DC3;
    color: white;
    font-weight: normal;
    margin-right: 5px
    
}

.title-promo-list{
    display: block;

    font-weight: bold;
    width: 20vw;
    margin-top: 20px;
}

.landing-slider-mobile-wrapper .title-promo-list{
    display: block;

    font-weight: bold;
    width: 40vw;
    
}



.slider-promo-banner-container{
    width: 16%;
    min-width: 200px;
    height:100%;
    margin-right: 1vw;
    background-color: aquamarine;
}

.container-slider-promo{

}

.dummies{
    background-color: grey;
}

.slider-promo-banner{
    width: 100%;
    min-width: 130px;
    height: 100%;
}

.slider-promo-detail {
    margin: 0 1vw 0 1vw;
    height: 30%
}

.categories{
    height: 100%;
    width: 100%;
    margin: 0 1vw 0 1vw;
}

.slider-promo-group-container {
    height: 100%;
    width: 84%
}

.slider-promo-content{
    /* background-color: silver; */
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: white;
    height: 320px;
}
