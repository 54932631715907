.item-art-slider{
    background-color: #757575;

  
    height: 20vw;
    width: 20vw;
    margin-right: 2vw;
    margin-top: 1vw;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.portrait{
  height: 20vw!important;
  width: 20vw!important;
  margin-right: 2vw;
}

.mobile-portrait{
  height: 28vw!important;
  width: 28vw!important;
  margin-right: 1vw;
  margin-right: 2vw;
  background-position: center;
}



.landing-slider-mobile-wrapper .container-item-art{
    // height: 25vw;
    background-repeat: no-repeat;
    padding-right: 2vw;
    background-size: cover;
}

.item-name{
  padding-top: 0.4vw;
  font-size: 18px;
  //limit character
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 18vw;
  float: left;
  color: #DDDDDD;
}

.item-name-article{
  padding-top: 0.4vw;
  font-size: 18px;
  //limit character
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 18vw;
  float: left;
  color: #DDDDDD;
}

.item-name-mobile{
  padding-top: 1vw;
  font-size: 14px;
  //limit character
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 28vw;
  float: left;
  color: #DDDDDD;
}

.item-name-art{
  padding-top: 0.4vw;
  font-size: 18px;
  //limit character
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 14vw;
  float: left;
  color: #DDDDDD;
}

.item-count-like{
  padding-top: 0.4vw;
  font-size: 18px;
  //limit character
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 18vw;
  color: #DDDDDD;
  float: right;
  margin-right: 2vw;
}

.landing-slider-mobile-wrapper .item-art-mobile{
    background-color: #757575;
    height: 28vw!important;background-repeat: no-repeat;
    width: 28vw;
    background-size: cover;
}




#art-slider{


.arrow-banner-left{
    left: 4.9!important
  }
  
  .arrow-banner-right{
    right: 4.9!important
  }
  
  #next-arrow-banner, #prev-arrow-banner{
    position: absolute;
    top: 30%;
  }
  
  #prev-arrow-banner {
    cursor: pointer;
    left: 4.9%;
    z-index: 100;
  }
  
  #next-arrow-banner {
    display: flex;
    text-align: end;
  }
  
  #prev-arrow-banner > i{
    /* width: 3em;
    height: 3em; */
    font-size: 5vw;
    color: #ed008a6e;
  }
  
  #prev-arrow-banner > i:hover{
  
    color: #ed008a;
  }
  
  #next-arrow-banner > i:hover{
  
    color: #ed008a;
  }
  
  #next-arrow-banner{
    cursor: pointer;
    right: 4.9%;
  }
  
  #next-arrow-banner > i{
    width: 3em;
    height: 3em;
    font-size: 5vw;
    color: #ed008a6e;
  }
  
  #next-arrow-landing, #prev-arrow-landing{
    position: absolute;
    top: 40%;
  }
  
  #prev-arrow-landing {
    cursor: pointer;
    left: 0%;
    z-index: 100;
  
  }
  #next-arrow-landing {
    display: flex;
    text-align: end;
  }
  
  #prev-arrow-landing > i{
    font-size: 4em;
    color: #ed008a6e;
  }
  
  #prev-arrow-landing > i:hover{
  
    color: #ed008a;
  }
  
  #next-arrow-landing > i:hover{
  
    color: #ed008a;
  }
  
  #next-arrow-landing{
    cursor: pointer;
    right: 2.5%;
  }
  
  #next-arrow-landing > i{
  
    font-size: 4em;
    color: #ed008a6e;
  }
  
  
  /* Dots */
  .slick-dotted.slick-slider {
    margin-bottom: 30px;
  }
  
  .slick-dots {
    position: absolute;
    bottom: -60px;
  
    display: block;
  
    width: 100%;
    padding: 0;
    margin: 0;
  
    list-style: none;
  
    text-align: center;
  }
  .slick-dots li {
    position: relative;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    transition: width 0.3s ease-in-out;
  }
  .slick-dots li button {
    font-size: 0;
    line-height: 0;
  
    display: block;
  
    width: 10px;
    height: 10px;
    padding: 5px;
  
    cursor: pointer;
  
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
  }
  .slick-dots li button:hover,
  .slick-dots li button:focus {
    outline: none;
  }
  .slick-dots li button:hover:before,
  .slick-dots li button:focus:before {
    opacity: 1;
  }
  .slick-dots li button:before {
    font-family: "slick";
    font-size: 6px;
    line-height: 20px;
  
    position: absolute;
    top: 0;
    left: 0;
  
    width: 10px;
    height: 10px;
  
    content: "•";
    text-align: center;
  
    opacity: 0.25;
    color: black;
  
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: black;
  }
  
  /* Custom Slick Dots */
  
  
  .ft-slick__dots--custom {
    height: 13px;
    width: 13px;
    background: #e263ad;
  
    border-radius: 50%;
    position: relative;
    transition: width 0.3s ease-in-out;
  }
  
  .slick-dots li {
    width: 14px;
    margin: 3px 2px;
    transition: width 0.3s ease-in-out;
  }
  
  .slick-dots .slick-active {
    width: 32px;
    transition: width 0.3s ease-in-out;
    
  
  }
  
  .slick-dots .slick-active .ft-slick__dots--custom {
    width: 32px;
    top: 0px;
    overflow: hidden;
    background-color: #ED0089;
    border-radius: 10px;
  }
}