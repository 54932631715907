@media (max-width: 900px) {
  .item-mobile {
      display: block!important;
  }
  .item-desktop{
    display: none!important;
  }
  .container-button-art {
    img{
      height: 40px!important;
      
    }
  }
  .wrapper-button-art-share {
    
    width: 30px!important;
    height: 30px!important;
    

    
  }
}

@media (min-width: 901px){
  .item-mobile {
    display: none!important;
  }
  .item-desktop{
    display: block!important;
  }
}
.modal-art-wrapper {
  // top: 61px;
  animation: fly .4s ease-in;
  overflow: auto;
  padding-right: 0 !important;
  padding-left: 0 !important;

  .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    max-width: 100%;
  }

  .modal-content {
    height: auto;
    min-height: 100%;
    background: transparent;
  }

  .modal-header {
    border-bottom: none;
    padding: 10px 15px;

    .close {
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      line-height: 40px;
      color: #ffffff;
      outline: none;
      border: none;
      opacity: 1;
    }

  }

  .modal-body {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px 20px 0px 0px;
    background: #212529;
    height: 50vh;
    overflow: auto;
  }

  .wrapper-art {
    margin: 2em 5vw;

    .wrapper-video-art {
      width: 100%;
      height: 100%;
    }

    .wrapper-audio-art {
      width: 100%;
      height: 40vh;
    }

    .wrapper-image-art {
      position: relative;

      img {
        width: 100%;
        height: 100%;
        border-radius: 30px;
      }

      .icon-image-art-zoom {
        position: absolute;
        top: 15px;
        right: 25px;
        height: 2.85em;
        width: 2.85em;
        border-radius: 50%;
        background-color: #00000090;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        cursor: pointer;
      }
    }

    .wrapper-conten-art {
      margin-top: 2em;

      .title-wrapper-art {
        font-weight: bold;
        font-size: 40px;
        line-height: 40px;
        overflow-wrap: break-word;
      }

      .wrapper-flex-art-button-text {
        display: flex;
        margin-top: 2em;
        margin-bottom: 1.5em;

        .date-art-button {
          margin: auto auto auto 0px;
          font-size: 14px;
          line-height: 20px;
        }
        .art-liked{
          color: #FFFFFF !important;
          background-position:right bottom !important;
        }

        .wrapper-button-art {
          position: relative;
          // background: #fcd9ed;
          background: linear-gradient(to right, #fcd9ed 50%, #ED0089 50%);
          background-size: 200% 100%;
          background-position:left bottom;
          transition:all 1s ease;
          color: #ED0089;
          border-radius: 60px;
          width: 100px;
          height: 38px;
          cursor: pointer;

          .container-button-art {
            position: absolute;
            left: 50%;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            display: flex;
            img{
              height: 40px!important;
              border-radius: 50%;
            }
            .child-icon-buton-art {
              font-size: 20px;
            }

            .child-text-button-art {
              font-size: 15px;
              line-height: 18px;
              margin-left: 7px;
              margin-top: 2px;
              font-weight: bold;
            }
          }
        }
      }
      .wrapper-button-art-share {
        position: relative;
        background: #FFFFFF;
        border-radius: 60px;
        width: 40px;
        height: 40px;
        color: #222222;
        // margin-right: 1em;
        cursor: pointer;

        
      }

      .desc-wrapper-art {
        h1{
          font-size: 19px;
        }
        h2{
          font-size: 18px;
        }
        h3{
          font-size: 16px;
        }
        h4{
          font-size: 15px;
        }
        h5{
          font-size: 14px;
        }
        h6{
          font-size: 14px;
        }
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;
        border-width: 1px;
        border-top: #CCCCCC;
        border-bottom: #CCCCCC;
        border-top-style: solid;
        border-bottom-style: solid;
        padding: 20px 0px 30px 0px;
        margin: 1em 0;

        .button-social-art img{
          width: 3vw;
        }

        .wrapper-avatar-art-seder {
          display: flex;
          margin-top: 3em;
          .avatar-art-seder {
            background-image: url('https://upload.wikimedia.org/wikipedia/id/d/d5/Aang_.jpg');
            min-width: 46px;
            min-height: 46px;
            background-size: cover;
            background-position: center center;
            border-radius: 50%;
          }

          .avatar-art-seder-user {
            margin-top: 0.5em;
            margin-left: 1em;
            font-weight: bold;
            font-size: 14px;
            line-height: 17px;
            overflow-wrap: break-word;
          }
        }
      }
    }
  }

  .wrapper-more-like-art-modal {

    .wrapper-video-item-more-like-modal {
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
      position: relative;
      display: flex;
      cursor: pointer;
      .video-item-more-like-modal-cover-video {
        border-radius: 30px;
      }
      .video-item-more-like-modal-overlay {
        width: 100%;
        height: 100%;
        position: absolute !important;
        z-index: 100;
        border-radius: 30px;
        opacity: 0.85;

        img {
          width: 85px;
        }
      }
    }

    .wrapper-audio-item-more-like-modal {
      background-color: grey;
      width: 100%;
      height: 100px;
      border-radius: 30px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
      position: relative;
      cursor: pointer;
      audio {
        max-width: 90%;
      }
      .audio-item-more-like-modal-overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.158);
        border-radius: 30px;
      }
    }

    .wrapper-item-image-more-like-modal {
      cursor: pointer;
      position:relative;
      img{
        border-radius: 30px;
        width: 100%;
        height: 100%;
      }
      .item-image-title-more-like-modal{
        position: absolute;
        right: 25px;
        bottom: 0;
        font-weight: bold;
        font-size: 18px;
        color: #FFFFFF;
        opacity: 0; 
        max-width: 200px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-align: right;
      }
    }
    
    .wrapper-item-image-more-like-modal:hover{
      img{
        transition: all .2s ease;
        background: #000000;
        opacity: 0.5;
      }
      .item-image-title-more-like-modal{
        opacity: 1;
        transform: rotate(
          0deg
          ) scale(1) translateY(-10px);
              transition: all .3s ease;
              z-index: 400;
              opacity: 1;
      }
    }

    .wrapper-item-title-love-more-like-modal {
      margin-top: 1em;
      .item-username-more-like {
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
      }
      .item-count-more-like {
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        .icon-more-like-count {
          margin: 0px .3em;
        }
      }
    }

  }

  .wrapper-suggest-more-like-username{
    font-weight: bold;
    font-size: 20px;
    line-height: 40px;
    margin: 1.8em 0px;
    overflow-wrap: break-word;
    line-height: 1.2;
  }


}

.container-button-art {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: flex;
  img{
    height: 40px;
    border-radius: 50%;
  }
  .child-icon-buton-art {
    font-size: 20px;
  }

  .child-text-button-art {
    font-size: 15px;
    line-height: 18px;
    margin-left: 7px;
    margin-top: 4px;
    font-weight: bold;
  }
}