.events{
    cursor: pointer;
  }
  .events-footer{
    height: 42px;
    width: 288px;
    background-color: #31363A;
    border-radius: 0px 0px 12px 12px;
    border-top: 1px solid #535B62;
    padding: 0.5em 0px 0px 1em;
    .events-list-title{
      font-weight: 700;
      font-size: 16px;
      line-height: 19.2px;
      color: #F9F9F9;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .events-content{
    width: 288px;
    height: 96px;
    background-color: #31363A;
    padding: 1em;
    .events-list-title{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 700;
      font-size: 16px;
      line-height: 19.2px;
      color: #F9F9F9;
      padding-bottom: 0.5em;
    }
    .events-list-title-small{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 400;
      font-size: 14px;
      line-height: 16.8px;
      color: #F9F9F9;
      padding-bottom: 0.5em;
      margin-top: 2px;
      .icon{
        padding-right: 1em;
      }
    }
  }

  .events-list-event{
    margin: 1.5em 0px 0px 0px;
    // display: flex;
    .events-list-event-thum{
      // display: block;
      width: 288px;
      height: 160px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      border-radius: 12px 12px 0px 0px;
    }
    
    .events-list-event-content{
      padding-left: 1em;
      width: 100%;

      .events-list-title{
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        padding-right: 1em;
        overflow: hidden;
        text-overflow: ellipsis;
        // display: -webkit-box;
        // -webkit-line-clamp: 3;
        // -webkit-box-orient: vertical;
      }
    }
  }

  @media(max-width: 500px) {
    .events{
      padding-right: 1vw;
    }
    .events-footer{
        width: 100% !important;
    }
    .events-list-event{
        width: 100% !important;
        .events-list-event-thum{
            width: 100% !important;
        }
    }
    .events-content{
        width: 100% !important;
        height: 140px;
    }
  }