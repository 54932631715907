
#slider-landing{
    width: 100%;
    height: 400px;
    
}

.slider-landing-categories{
    height: 70%;
    width: 95%
}

.item-landing-slider{
  background-color: #757575;
  width: 20vw!important;
  height: 25vw!important;
  background-repeat: no-repeat;
  background-size: cover;
}

.landing-slider-mobile-wrapper .item-landing-slider{
    background-color: #757575;
    width: 40vw!important;
    height: 45vw!important;
    background-repeat: no-repeat;
    background-size: cover;
  }

.list-container-landing-slider{
  display: flex!important;
  justify-content: center!important;
}

.container-slider-landing{
    /* height: 300px; */
    /* margin-top:62px; */
    width: 100%;
    height: 320px;
}

.slider-landing-banner-container{
    width: 16%;
    min-width: 200px;
    height:100%;
    margin-right: 1vw;
    background-color: aquamarine;
}

.container-slider-landing{

}

.dummies{
    background-color: grey;
}

.slider-landing-banner{
    width: 100%;
    min-width: 130px;
    height: 100%;
}

.slider-landing-detail {
    margin: 0 1vw 0 1vw;
    height: 30%
}

.categories{
    height: 100%;
    width: 100%;
    margin: 0 1vw 0 1vw;
}

.slider-landing-group-container {
    height: 100%;
    width: 84%
}

.slider-landing-content{
    /* background-color: silver; */
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: white;
    height: 320px;
}

.caption-name{
    margin-top: 0.7vw;
    color: #DDDDDD;
    font-size: 18px;
}

.caption-count{
    color: #999999;
    font-size: 15px;
}