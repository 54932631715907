.section-coundownbanner{
  .container-content{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 10px;
    text-align: center;
    .section-count-down{
      display: flex;
      justify-content: center;
      background: #e8dab3;
      color: #fff;
      text-align: center;
      border: 20px solid #e81e88;
      border-radius: 15px;
      padding: 1em 3em;
      .bg-a{
          width: 7vw;
          background: #403c31;
          font-size: 61px;
          font-style: normal;
          font-weight: normal;
          border-radius: 10px;
          margin: 0px 10px;
      }
      .bg-b{
        color: black;
        font-size: 20px;
      }

    }
    .title-date-content{
      font-size: 80px;
      font-weight: bold;
    }
    .subtitle-date-content{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1.5rem;
      font-size: 1rem;
      font-weight: bold;
      .section-bg-flex{
        background: #e8dab3;
        text-align: center;
        border: 10px solid #e81e88;
        border-radius: 10px;
        padding: 8px;
      }
      .right-content{
        color: #e81e88;
        .right-content-black{
          color: black;
        }
      }
    }
  }
  .footer-page-coutdown{
  background-color: #E61E87;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  overflow:hidden;
  padding: 10px 0px;
  .section-footer-countdown{
    display: flex;
    justify-content: center;
    .item-footer-countdown{
    height: 35px;
    margin-right: 20px;
    cursor: pointer;
    }
  }
}
}


/*small devices*/
@media screen and (max-width: 767px)
{
  .section-coundownbanner{
    .container-content{
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      padding: 10px;
      text-align: center;
      .section-count-down{
        display: flex;
        justify-content: center;
        background: #e8dab3;
        color: #fff;
        text-align: center;
        border: 10px solid #e81e88;
        border-radius: 15px;
        padding: 15px;
        .bg-a{
            width: 15vw;
            background: #403c31;
            font-style: normal;
            font-weight: normal;
            font-size: 3.5rem;
            border-radius: 10px;
            }

        .bg-b{
          padding: 0px 1em;
          font-size: 1.5rem;
        }
      }
      .subtitle-date-content{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.3rem;
        font-weight: bold;
        margin-top: 2rem;
        .section-bg-flex{
          background: #e8dab3;
          text-align: center;
          border: 8px solid #e81e88;
          border-radius: 10px;
          padding: 5px;
        }
        .right-content{
          color: #e81e88;
        }
      }
    }
    .footer-page-coutdown{
      padding: 10px 0px;
      .section-footer-countdown{
        display: flex;
        justify-content: center;
        .item-footer-countdown{
          height: 30px;
          margin-right: 20px;
          cursor: pointer;
        }
      }
    }
  }
}

/* Tablets */
@media (min-width: 768px) and (max-width: 1024px) {
  .section-coundownbanner{
    .container-content{
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      padding: 10px;
      text-align: center;
      .section-count-down{
        display: flex;
        justify-content: center;
        background: #e8dab3;
        color: #fff;
        text-align: center;
        border: 10px solid #e81e88;
        border-radius: 15px;
        padding: 15px;
        .bg-a{
          width: 15vw;
          background: #403c31;
          font-style: normal;
          font-weight: normal;
          font-size: 3.5rem;
          border-radius: 10px;
          }

      .bg-b{
        padding: 0px 1em;
        font-size: 1.5rem;
      }
      }
      .subtitle-date-content{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.3rem;
        font-weight: bold;
        margin-top: 2rem;
        .section-bg-flex{
          background: #e8dab3;
          text-align: center;
          border: 8px solid #e81e88;
          border-radius: 10px;
          padding: 5px;
        }
        .right-content{
          color: #e81e88;
        }
      }
    }
    .footer-page-coutdown{
      padding: 10px 0px;
      .section-footer-countdown{
        display: flex;
        justify-content: center;
        .item-footer-countdown{
          height: 30px;
          margin-right: 20px;
          cursor: pointer;
        }
      }
    }
  }
}