.modal-promoc2-wrapper {
  // top: 61px;
  animation: fly .4s ease-in;
  overflow: auto;
  padding-right: 0 !important;
  padding-left: 0 !important;

  
  .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    max-width: 100%;
  }

  .modal-content {
    height: auto;
    min-height: 100%;
    background: transparent;
  }

  .modal-header {
    border-bottom: none;
    padding: 10px 15px;
    .close {
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      line-height: 40px;
      color: #ffffff;
      outline: none;
      border: none;
      opacity: 1;
    }

  }

  .modal-body {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px 20px 0px 0px;
    background: #212529;
    height: 50vh;
    overflow: auto;
  }

  .wrapper-promoc2 {
    margin: 2em 5vw;
    .wrapper-video-promoc2{
      width: 100%;
      height: 100%;
    }
    .wrapper-audio-promoc2{
      width: 100%;
      height: 100%;
    }
    .wrapper-image-promoc2{
      position: relative;
      img{
        width: 100%;
        height: 100%;
      }
      .icon-image-promoc2-zoom{
        position: absolute;
        top: 15px;
        right: 25px;
        height: 2.85em;
        width: 2.85em;
        border-radius: 50%;
        background-color: #00000090;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        cursor: pointer;
      }
    }
    .wrapper-conten-promoc2 {
      margin-top: 2em;
      .title-wrapper-promoc2 {
        font-weight: bold;
        font-size: 40px;
        line-height: 40px;
      }
      .wrapper-flex-promoc2-button-text {
        display: flex;
        margin-top: 2em;
        margin-bottom: 1.5em;
        .date-promoc2-button {
          margin: auto auto auto 0px;
          font-size: 14px;
          line-height: 20px;
        }
        .wrapper-button-promoc2 {
          position: relative;
          background: #ED0089;
          border-radius: 60px;
          width: 100px;
          height: 38px;
          cursor: pointer;
          .container-button-promoc2 {
            position: absolute;
            left: 50%;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            display: flex;
            
            .child-icon-buton-promoc2 {
              font-size: 20px;
            }
            .child-text-button-promoc2 {
              font-size: 15px;
              line-height: 18px;
              margin-left: 7px;
              margin-top: 2px;
              font-weight: bold;
            }
          }
        }
      }
      .wrapper-button-promoc2-share {
        position: relative;
        background: #FFFFFF;
        border-radius: 60px;
        width: 40px;
        height: 40px;
        color: #222222;
        margin-right: 1em;
        cursor: pointer;
        .container-button-promoc2 {
          position: absolute;
          left: 50%;
          top: 50%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          display: flex;
          border-radius: 100%;
          width: 40px;
            height: 40px;
          img{
            border-radius: 100%;
          }
          .child-icon-buton-promoc2 {
            font-size: 20px;
          }
          .child-text-button-promoc2 {
            font-size: 15px;
            line-height: 18px;
            margin-left: 7px;
            margin-top: 4px;
            font-weight: bold;
          }
        }
      }
      .desc-wrapper-promoc2 {
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;
        border-width: 1px;
        border-top: #CCCCCC;
        border-bottom: #CCCCCC;
        border-top-style: solid;
        border-bottom-style: solid;
        padding: 20px 0px 30px 0px;
        margin: 1em 0;
        .wrapper-avatar-promoc2-seder{
          display: flex;

          .avatar-promoc2-seder{
            background-image: url('https://upload.wikimedia.org/wikipedia/id/d/d5/Aang_.jpg');
            width: 46px;
            height: 46px;
            background-size: cover;
            background-position: center center;
            border-radius: 50%;
          }
          .avatar-promoc2-seder-user{
            margin-top: 0.5em;
            margin-left: 1em;
            font-weight: bold;
            font-size: 14px;
            line-height: 17px;
          }
        }
      }
      .insagram-modal-promoc2{
        width: 50px;
      }
      .insagram-modal-promoc2:hover{
        scale: (1.1);
      }
    }
  }
}