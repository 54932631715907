.btn-view-all{
    width: 125px;
    height: 137px;
    /* background-color: aqua; */
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center center;
    text-align: center;
}

.title-all-vid{
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    padding-top: 7px;
}

.img-arrow{
    padding-top: 30px;
}