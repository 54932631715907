.SubmitArt {}

.upload-image-container {
    width: 100%;
    height: 25vw;
    border-style: dotted;
    border-radius: 30px;
    border-color: white;
}

.browse-link{
    color: #FF0D87!important;
    cursor: pointer;
}

.input-deskripsi-produk{
    height: 200px!important;
}

.submit-art-site-logo-vindes{
    width: 70%
}

.uploadertes{
    background-color: #FF0D87;
    width: 150px!important;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    border:  1px dashed
}

.preview-image{
    width: 100%;
    img{
        width: 50%
    }
}

.text-option-dark{
    color: black;
}

.ck-content {
    color: #000000;
    height: 400px;
}