/* body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
/* Rectangle 6 */

.btn-get-started {
  background-color: #ECCC2F;

  
}

.latest-landing-sec{
  background-color: linear-gradient(180deg, #FEFDEF 0%, #F9F1B8 100%);
}

.container-header {
    background-repeat: no-repeat;
    width: 100%;
    position: fixed;
    top: 0%;
    height:61px;;
    border-bottom: 8px solid #222;
    z-index: 1000;
}


.container-header .latest-landing-header {
  
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding:1em 4em;
  position: absolute;
  width: 100%;
  background-color: #ECCC2F;
  height:61px;
  
}

.button-rounded {
  /* Rectangle 4 */
  display: flex;
  background: #ED0089;
  font-size: 15px;
  padding:  2.8% 20px 0px 20px;
  border-radius: 100px 100px 100px 100px;
  height: 33px;
  align-items: center;
  margin: 10px;
  color: white
}

.input-rounded {
  font-size: 15px;
  border-radius: 100px 100px 100px 100px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.inputWithIcon input[type="text"] {
  padding-left: 20px;
}

.inputWithIcon {
  height: 33px;
  position: relative;
}

.inputWithIcon i {
  position: absolute;
  right: 0;
  top: 40%;
  padding-right: 40px;
  color: #aaa;
  transition: 0.3s;
}

.inputWithIcon input[type="text"]:focus + i {
  color: dodgerBlue;
}

.inputWithIcon.inputIconBg i {
  background-color: #aaa;
  color: #fff;
  padding: 9px 4px;
  
}

.inputWithIcon.inputIconBg input[type="text"]:focus + i {
  color: #fff;
  background-color: dodgerBlue;
}

.main-footer-sec-content, #footer{
  background-color: #ED0089;
  background-size: cover;
    background-repeat: no-repeat;
    background-position-y: -6vh;
}

.arrow-banner-left{
  left: 4.9!important
}

.arrow-banner-right{
  right: 4.9!important
}

#next-arrow-banner, #prev-arrow-banner{
  position: absolute;
  top: 30%;
}

#prev-arrow-banner {
  cursor: pointer;
  left: 4.9%;
  z-index: 100;
}

#next-arrow-banner {
  display: flex;
  text-align: end;
}

#prev-arrow-banner > i{
  /* width: 3em;
  height: 3em; */
  font-size: 5vw;
  color: #ed008a6e;
}

#prev-arrow-banner > i:hover{

  color: #ed008a;
}

#next-arrow-banner > i:hover{

  color: #ed008a;
}

#next-arrow-banner{
  cursor: pointer;
  right: 4.9%;
}

#next-arrow-banner > i{
  width: 3em;
  height: 3em;
  font-size: 5vw;
  color: #ed008a6e;
}

#next-arrow-landing, #prev-arrow-landing{
  position: absolute;
  top: 40%;
}

#prev-arrow-landing {
  cursor: pointer;
  left: 1.5%;
  z-index: 100;

}
#next-arrow-landing {
  display: flex;
  text-align: end;
}

#prev-arrow-landing > i{
  font-size: 4em;
  color: #ed008a6e;
}

#prev-arrow-landing > i:hover{

  color: #ed008a;
}

#next-arrow-landing > i:hover{

  color: #ed008a;
}

#next-arrow-landing{
  cursor: pointer;
  right: 1.5%;
}

#next-arrow-landing > i{

  font-size: 4em;
  color: #ed008a6e;
}


/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -60px;

  display: block;

  width: 100%;
  padding: 0;
  margin: 0;

  list-style: none;

  text-align: center;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
  transition: width 0.3s ease-in-out;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;

  display: block;

  width: 10px;
  height: 10px;
  padding: 5px;

  cursor: pointer;

  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;

  position: absolute;
  top: 0;
  left: 0;

  width: 10px;
  height: 10px;

  content: "•";
  text-align: center;

  opacity: 0.25;
  color: black;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: black;
}

/* Custom Slick Dots */


.ft-slick__dots--custom {
  height: 13px;
  width: 13px;
  background: #e263ad;

  border-radius: 50%;
  position: relative;
  transition: width 0.3s ease-in-out;
}

.slick-dots li {
  width: 14px;
  margin: 3px 2px;
  transition: width 0.3s ease-in-out;
}

.slick-dots .slick-active {
  width: 32px;
  transition: width 0.3s ease-in-out;
  

}

.slick-dots .slick-active .ft-slick__dots--custom {
  width: 32px;
  top: 0px;
  overflow: hidden;
  background-color: #ED0089;
  border-radius: 10px;
}

