.slider-event{
  margin-top: 61px;
}

.events{
  cursor: pointer;
}

.section-featured-event{
  padding-left: 5vw;
  padding-right: 5vw;
  padding-top: 8em;
}

.section-wrapper-event{
    padding-left: 5vw;
    padding-right: 5vw;
    padding-top: 8em;
    padding-bottom: 88px;

    .section-btn{
      display: flex;
      justify-content: center;

      .btn-all-events{
        margin-top: 60.29px;
        cursor: pointer;
        background: #F6007A;
        color: #ffffff;
        border-radius: 12px;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.02em;
        width: 229.23px;
        height: 53px;
        text-align: center;
        text-transform: none;
      }
    }

    .section-footer{
      height: 42px;
      width: 288px;
      background-color: #31363A;
      border-radius: 0px 0px 12px 12px;
      border-top: 1px solid #535B62;
      padding: 0.5em 0px 0px 1em;
      .item-list-title{
        font-weight: 700;
        font-size: 16px;
        line-height: 19.2px;
        color: #F9F9F9;
        overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1; /* number of lines to show */
              line-clamp: 1; 
      -webkit-box-orient: vertical;
      }
    }
    .section-content{
      width: 288px;
      height: 96px;
      background-color: #31363A;
      padding: 1em;
      .item-list-title{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 700;
        font-size: 16px;
        line-height: 19.2px;
        color: #F9F9F9;
        padding-bottom: 0.5em;
      }
      .item-list-title-small{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 400;
        font-size: 14px;
        line-height: 16.8px;
        color: #F9F9F9;
        padding-bottom: 0.5em;
        .icon{
          padding-right: 1em;
        }
      }
    }

    .section-list-event{
      margin: 1.5em 0px 0px 0px;
      // display: flex;
      .item-list-event-thum{
        // display: block;
        width: 288px;
        height: 139.71px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        border-radius: 12px 12px 0px 0px;
      }
      
      .item-list-event-content{
        padding-left: 1em;
        width: 100%;

        .item-list-title{
          font-weight: bold;
          font-size: 20px;
          line-height: 24px;
          padding-right: 1em;
          overflow: hidden;
          text-overflow: ellipsis;
          // display: -webkit-box;
          // -webkit-line-clamp: 3;
          // -webkit-box-orient: vertical;
        }
      }
    }
}

.section-past-event{
  margin: auto;
  width: 1250px;
  height: 40px;
  background: #31363A;
  border-radius: 12px;
  cursor: pointer;

  .past-event-title{
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    padding: 0.6em 0px 0px 1em;
  }
  .past-event-arrow{
    margin: 0;
    position: absolute;
    right: 4%;
    bottom: -10%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

.section-button-download{
  margin-top: 78px;
}

@media(max-width: 500px) {
  .section-footer{
    width: 100% !important;
  }
  .section-list-event{
    width: 100% !important;
  }
  .section-content{
    width: 100% !important;
  }
  .item-list-event-thum{
    width: 100% !important;
  }
  .section-past-event{
    width: 100%;
  }
}