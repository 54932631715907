.SubmitArt {}

.upload-image-container {
    width: 100%;
    height: 25vw;
    border-style: dotted;
    border-radius: 30px;
    border-color: white;
}

.browse-link{
    color: #FF0D87!important;
    cursor: pointer;
}

.input-deskripsi-produk{
    height: 200px!important;
}

.submit-art-site-logo-vindes{
    width: 70%
}

#type{
    height: auto;
}

.text-option-dark{
    color: black;
}