#modal-body-thank {
    background-color: #E61E87;
    color: white;
    font-size: 2em;
    text-align: center;
    font-weight: bold;
    border: 3px solid #27a4e8;
    padding: 0;
}

.text-content {
    border: 5px solid white;
    padding: 1rem;
}